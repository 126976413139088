<template>
  <div class="new-insert">
    <!-- <Tabs :tab="tab" @checkTab="checkTab"></Tabs> -->
    <div class="" v-if="!hasFb && !isMuying && !isAiFb && !isBj && !isM22">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="checkTab('Questions', true)">
            <el-image v-if="step == 'Questions' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera' || step == 'Bodily') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
            <!-- <i class="el-icon-user-solid"></i> -->
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('Bodily', true)">
            <el-image v-if="step == 'Bodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')" v-if="!isAiJk">
          <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
            <el-image v-if="step == 'Biochemical' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions' || step == 'Bodily') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="" v-if="isAiFb">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="checkTab('FbQuestions', true)">
            <el-image v-if="step == 'FbQuestions' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon" v-if="(step == 'FbBodily') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
            <!-- <i class="el-icon-user-solid"></i> -->
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('FbBodily', true)">
            <el-image v-if="step == 'FbBodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'FbQuestions') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <!-- <el-image class="insert-icon" :src="require('@/assets/images/pay-success.png')"></el-image> -->
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="iiiiiii" v-if="isBj">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="checkTab('Questions', true)">
            <el-image v-if="step == 'Questions' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera' || step == 'Bodily') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('Bodily', true)">
            <el-image v-if="step == 'Bodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
            <el-image v-if="step == 'Biochemical' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image
              v-if="(step == 'Personal' || step == 'Questions' || step == 'Bodily' || step == 'InsertFb') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="" v-if="hasFb">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="checkTab('Questions', true)">
            <el-image v-if="step == 'Questions' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera' || step == 'Bodily') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('Bodily', true)">
            <el-image v-if="step == 'Bodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'InsertFb' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t64')">
          <div class="icon-box" slot="icon" @click="checkTab('InsertFb', true)">
            <el-image v-if="step == 'InsertFb' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions' || step == 'Bodily') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
            <el-image v-if="step == 'Biochemical' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image
              v-if="(step == 'Personal' || step == 'Questions' || step == 'Bodily' || step == 'InsertFb') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="" v-if="isMuying">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t2')">
          <div class="icon-box" slot="icon" @click="checkTab('Questions', true)">
            <el-image v-if="step == 'Questions' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/quest-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/quest.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'Newborn' || step == 'Puerpera' || step == 'myBodily') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('myBodily', true)">
            <el-image v-if="step == 'myBodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon"
              v-if="(step == 'Biochemical' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t62')">
          <div class="icon-box" slot="icon" @click="checkTab('Puerpera', true)">
            <el-image v-if="step == 'Puerpera' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/gravida-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Questions' || step == 'myBodily') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/gravida.png')"></el-image>
            <el-image class="insert-icon" v-if="(step == 'Biochemical' || step == 'Newborn') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t63')">
          <div class="icon-box" slot="icon" @click="checkTab('Newborn', true)">
            <el-image v-if="step == 'Newborn' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/newborn-int.png')"></el-image>
            <el-image
              v-if="(step == 'Personal' || step == 'Questions' || step == 'myBodily' || step == 'Puerpera') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/newborn.png')"></el-image>
            <el-image class="insert-icon" v-if="(step == 'Biochemical') && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
            <el-image v-if="step == 'Biochemical' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image
              v-if="(step == 'Personal' || step == 'Questions' || step == 'myBodily' || step == 'Newborn' || step == 'Puerpera') && type == 'insert'"
              class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <div class="iiiiiii" v-if="isM22">
      <el-steps :active="tab" class="steps-wrap">
        <el-step :title="$t('open.t1')">
          <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
            <el-image class="insert-icon" v-if="step == 'Personal' || type == 'update'"
              :src="require('@/assets/images/personal-icon.png')"></el-image>
            <el-image class="insert-icon" v-if="step != 'Personal' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t3')">
          <div class="icon-box" slot="icon" @click="checkTab('Bodily', true)">
            <el-image v-if="step == 'Bodily' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/shape-int.png')"></el-image>
            <el-image v-if="step == 'Personal' && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/shape.png')"></el-image>
            <el-image class="insert-icon" v-if="step == 'BiochemicalM22' && type == 'insert'"
              :src="require('@/assets/images/pay-success.png')"></el-image>
          </div>
        </el-step>
        <el-step :title="$t('open.t4')">
          <div class="icon-box" slot="icon" @click="checkTab('BiochemicalM22', true)">
            <el-image v-if="step == 'BiochemicalM22' || type == 'update'" class="insert-icon"
              :src="require('@/assets/images/bioch-int.png')"></el-image>
            <el-image v-if="(step == 'Personal' || step == 'Bodily') && type == 'insert'" class="insert-icon"
              :src="require('@/assets/images/bioch.png')"></el-image>
          </div>
        </el-step>
      </el-steps>
    </div>
    <router-view :isBj="isBj" :type="type" :is="step" :isBo="isBo" @handleHasAiFb="handleHasAiFb"
      @handleHasAiJk="handleHasAiJk" @handleHasBj="handleHasBj" @handleHasM22="handleHasM22" @handleHasFb="handleHasFb"
      :clearData="clearData" @handleMuying="handleMuying" :formData="formData" @stopQuery="$emit('stopQuery')"
      @checkTab="checkTab"></router-view>
  </div>
</template>

<script>

import { packageOpen } from '@/utils/axios'
import { Base64 } from 'js-base64';
// import { timesStr } from '@/utils'
// const Tabs = () => import('./Tabs');
const Personal = () => import('./Personal');
const Questions = () => import('./Questions');
const FbQuestions = () => import('./FbQuestions');
const Bodily = () => import('./Bodily');
const FbBodily = () => import('./FbBodily');
const InsertFb = () => import('./InsertFb');
const Biochemical = () => import('./Biochemical');
const BiochemicalM22 = () => import('./BiochemicalM22');
const myBodily = () => import('./muying/Bodily');
const Newborn = () => import('./muying/Newborn');
const Puerpera = () => import('./muying/Puerpera');

export default {
  name: 'newInsert',
  data () {
    return {
      tab: 1,//默认从个人信息开始填写
      step: 'Personal',
      hasFb: false,
      isMuying: false,
      isAiFb: false,
      isAiJk: false,
      isBj: false,
      isM22: false,
      formData: {
        country_id: ""
      },
      defFormData: {
        mobile: '',
        yyuserid: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',
        package: '',

        corp: '',

        height: '',
        weight: '',
        // bust: '',
        waist: '',
        hip: '',
        fat: '',
        chest: '',

        area: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "kdis": "K01", "level": "0" },
          { "kdis": "K02", "level": "0" },
          { "kdis": "K03", "level": "0" },
          { "kdis": "K04", "level": "0" },
          { "kdis": "K05", "level": "0" },
          { "kdis": "K06", "level": "0" },
          { "kdis": "K07", "level": "0" },
          { "kdis": "K08", "level": "0" },
          { "kdis": "K09", "level": "0" },
          { "kdis": "K10", "level": "0" },
          { "kdis": "K11", "level": "0" },
          { "kdis": "K12", "level": "0" },
          { "kdis": "K13", "level": "0" },
          { "kdis": "K14", "level": "0" },
          { "kdis": "K15", "level": "0" },
          { "kdis": "K16", "level": "0" },
          { "kdis": "K17", "level": "0" },
          { "kdis": "K18", "level": "0" },
          { "kdis": "K19", "level": "0" }
        ],
        oper: [],
        famdis: [],

        preweek: '',
        preweight: '',
        prewaist: '',
        // prebust: '',
        prehip: '',
        prefat: '',

        bsex: '',
        mage: '',
        week: '',
        bweight: '',
        aps1: '',

        sbp: '',
        dbp: '',

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        predis2: [],

        operator: '',// 当前后台操作人手机号

        sbpCombo: true,// 产品组合勾选(前台使用，用来确认勾选产品组合-基础套餐体检)

        //骨折信息
        qnmh: '',
        qntimes: '',
        qnpbf: '',
        selfcare: '',
        tscore: '',
        fbdisease: [
          { "fdis": "F00", "level": "1" },
          { "fdis": "F01", "level": "0" },
          { "fdis": "F02", "level": "0" },
          { "fdis": "F03", "level": "0" },
          { "fdis": "F04", "level": "0" },
          { "fdis": "F05", "level": "0" },
          { "fdis": "F06", "level": "0" },
          { "fdis": "F07", "level": "0" },
          { "fdis": "F08", "level": "0" },
          { "fdis": "F09", "level": "0" },
          { "fdis": "F10", "level": "0" },
          { "fdis": "F11", "level": "0" },
          { "fdis": "F12", "level": "0" },
          { "fdis": "F13", "level": "0" },
          { "fdis": "F14", "level": "0" },
          { "fdis": "F15", "level": "0" },
          { "fdis": "F16", "level": "0" },
          { "fdis": "F17", "level": "0" },
          { "fdis": "F18", "level": "0" },
          { "fdis": "F19", "level": "0" },
          { "fdis": "F20", "level": "0" },
          { "fdis": "F21", "level": "0" },
          { "fdis": "F22", "level": "0" },
          { "fdis": "F23", "level": "0" },
          { "fdis": "F24", "level": "0" },
          { "fdis": "F25", "level": "0" },
          { "fdis": "F26", "level": "0" },
          { "fdis": "F27", "level": "0" },
          { "fdis": "F28", "level": "0" },
          { "fdis": "F29", "level": "0" },
          { "fdis": "F30", "level": "0" },
          { "fdis": "F31", "level": "0" },
          { "fdis": "F32", "level": "0" },
          { "fdis": "F33", "level": "0" },
          { "fdis": "F34", "level": "0" }
        ],
        service: [
          { "svc": "V01", "need": "1" },
          { "svc": "V02", "need": "1" },
          { "svc": "V03", "need": "1" },
          { "svc": "V04", "need": "1" },
          { "svc": "V05", "need": "1" },
          { "svc": "V06", "need": "1" },
          { "svc": "V07", "need": "1" },
          { "svc": "V08", "need": "1" },
          { "svc": "V09", "need": "1" },
          { "svc": "V10", "need": "1" },
          { "svc": "V11", "need": "1" },
          { "svc": "V12", "need": "1" },
          { "svc": "V13", "need": "1" },
          { "svc": "V14", "need": "1" },
          { "svc": "V15", "need": "1" },
          { "svc": "V16", "need": "1" },
          { "svc": "V17", "need": "1" },
        ],
      }
    }
  },
  components: {
    // Tabs,
    Personal,
    Questions,
    FbQuestions,
    Bodily,
    FbBodily,
    InsertFb,
    myBodily,
    Newborn,
    Puerpera,
    Biochemical,
    BiochemicalM22
  },
  props: ['type', 'dataId', 'isBo', 'reportCode', 'clearData'],
  mounted () {
    this.formData = this.defFormData
    if (this.dataId) {
      packageOpen({ act: 'query', id: this.dataId }).then((res) => {
        if (res.code == 200) {
          let dataBase = JSON.parse(Base64.decode(res.data))
          console.log("获取问卷数据", dataBase);
          let input_data = dataBase.input_data
          if (input_data.birth.length == 8) {
            let birthA = input_data.birth.split('')
            birthA.splice(4, 0, '/')
            birthA.splice(-2, 0, '/')
            input_data.birth = birthA.join('')

          } else {

          }
          let data = {}
          if (dataBase.package == 3 || dataBase.goods_id == 3) {
            let babyinf = dataBase.input_data.babyinf
            let preinf = dataBase.input_data.preinf
            data = { ...dataBase, ...input_data, ...preinf }
            data = { ...data, ...babyinf }
            delete data.input_data
            delete data.babyinf
            delete data.preinf
          } else {
            data = { ...dataBase, ...input_data }
            delete data.input_data
          }
          if (data.fbdisease && data.qnmh) {
            this.handleHasFb(true)
          }

          if (this.isBo) {
            data.examine = [
              { "ne": "WBC", "ve": "", "ut": "A" },
              { "ne": "HGB", "ve": "", "ut": "A" },
              { "ne": "PLT", "ve": "", "ut": "A" },
              { "ne": "FG", "ve": "", "ut": "B" },
              { "ne": "HBA1C", "ve": "", "ut": "A" },
              { "ne": "CHOL", "ve": "", "ut": "B" },
              { "ne": "LDLC", "ve": "", "ut": "B" },
              { "ne": "HDLC", "ve": "", "ut": "B" },
              { "ne": "TG", "ve": "", "ut": "B" },
              { "ne": "GOT", "ve": "", "ut": "A" },
              { "ne": "GPT", "ve": "", "ut": "A" },
              { "ne": "ALP", "ve": "", "ut": "A" },
              { "ne": "ALB", "ve": "", "ut": "B" },
              { "ne": "CRE", "ve": "", "ut": "B" },
              { "ne": "UA", "ve": "", "ut": "B" },
              { "ne": "EGFR", "ve": "", "ut": "A" }
            ]

            this.formData = data
            if (this.formData.yy_user_id) {
              this.formData.yyuserid = this.formData.yy_user_id
            }
            this.checkTab('Biochemical')
          } else {
            this.formData = data
            if (this.formData.yy_user_id) {
              this.formData.yyuserid = this.formData.yy_user_id
            }
          }
        }

        console.log("formData", JSON.parse(JSON.stringify(this.formData)));

      })
      if (this.type == 'update') {
        this.tab = 6
        return
      }
    }

  },
  watch: {

  },
  methods: {
    timesStr (date) {
      let d = new Date(date * 1000);    //根据时间戳生成的时间对象
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      let time = year + '/' + (month >= 10 ? month : '0' + month) + '/' + (day >= 10 ? day : '0' + day)

      return time
    },
    handleMuying (isMuying) {
      this.isMuying = isMuying
    },
    handleHasFb (hasFb) {
      this.hasFb = hasFb
    },
    handleHasAiFb (isAiFb) {
      this.isAiFb = isAiFb
    },
    handleHasAiJk (isAiJk) {
      this.isAiJk = isAiJk
    },
    handleHasBj (isBj) {
      this.isBj = isBj
    },
    handleHasM22 (isM22) {
      this.isM22 = isM22
    },
    //切换tab 参数 tab组件名
    checkTab (tab, isTab) {
      if (this.type == 'insert' && isTab) {
        return;
      }
      if (this.type == 'update') {
        this.tab = 6
        this.step = tab
        return
      }
      switch (tab) {
        case 'Personal':
          this.tab = 1
          break
        case 'Questions':
          this.tab = 2
          break
        case 'FbQuestions':
          this.tab = 2
          break
        case 'Bodily':
          this.tab = 3
          break
        case 'FbBodily':
          this.tab = 3
          break
        case 'myBodily':
          this.tab = 3
          break
        case 'InsertFb':
          this.tab = 4
          break
        case 'Puerpera':
          this.tab = 4
          break
        case 'Newborn':
          this.tab = 5
          break
        case 'Biochemical':
          // if(this.hasFb) {
          // 	this.tab = 5
          // } else if(this.isMuying) {
          // 	this.tab = 6
          // } else {
          // 	this.tab = 8
          // }
          this.tab = 6
          break
        case 'BiochemicalM22':
          this.tab = 6
          break
      }
      this.step = tab
    }
  }
}
</script>

<style scoped>
.new-insert>>>.el-step__icon {
  width: 36px;
  height: 36px;
  background-color: #F2F4FA;
  border-radius: 50%;
  cursor: pointer;
}

.new-insert>>>.el-step__icon.is-text {
  border: none;
}

.new-insert>>>.el-step.is-horizontal .el-step__line {
  height: 6px;
  top: 16px;
  background-color: #F2F4FA;
}

.new-insert>>>.el-step__line-inner {
  height: 6px;
  top: 16px;
  background: #6883FB;
}

.new-insert>>>.el-step__title {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525866;
  line-height: 16px;
}

.new-insert>>>.el-step__main {
  position: relative;
  left: -6px;
  margin-top: 8px;
}
</style>

<style lang="scss" scoped>
.steps-wrap {
  width: 752px;
  margin: 16px auto 0;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  // background-color: #6883FB;
  border-radius: 50%;
}

.el-icon-user-solid {
  font-size: 20px;
  color: #fff;
}

.insert-icon {
  width: 36px;
  height: 36px;
}
</style>
